:root {
    --key-size: max(4vw, 40px);
}
*
{
    margin: 0;
    padding: 0;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}
html,
body {

    background-color: #4482EE;
    font-family: 'Quicksand', sans-serif;
    display: flex;
    color: white;
}

a {
    text-decoration: none;
    color: inherit;
}

h1 {
    font-weight: 500;
}


.nav {
    font-size: 1.3em;
    position: absolute;
    top: 50px;
    left: 100px;
    width: 400px;
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.mobile-nav {
    display: none;
}

.toggle {
    position: relative;
}

.toggle:hover {
    color: darkblue;
}

.indicator {
    background-color: white;
    border-radius: 6px;
    height: 9px;
    width: 9px;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
}

.hidden {
    display: none!important;
}

/* 
* Home
*/
#home {
    z-index: 2;
    width: 100%;
    justify-content: left;
    padding: 0 0 0 100px;
    display: flex;
    align-items: center;
    margin: 150px 0 50px;
}

#intro-container {
    display: block;
    width: 80%;
}

.button {
    width: 200px;
    font-size: 1.1em;
    display: flex;
    justify-content: center;
    margin-top: 65px;
    height: 65px;
    align-items: center;
    border-radius: 8px;
    box-shadow:  8px 8px 18px #3a6fca, 
                -8px -8px 18px #4e96ff;
}

.button:hover {
    background: linear-gradient(145deg, #3d75d6, #498bff);
    box-shadow:  8px 8px 18px #3c72d1, 
                -8px -8px 18px #4c92ff;
}

.project-link:active,
.button:active {
    box-shadow: inset 10px 10px 20px #3a6fca, 
                inset -10px -10px 20px #4e96ff;
}

.intro {
    font-size: 3em;
    font-weight: 500;
    margin: 0;
}

#notification {
    visibility: hidden;
    top: -105px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 20px;
    height: 50px;
    width: 200px;
    right: 50%;
    position: fixed;
    margin: 1em 0 3em;
    border: 5px solid #4e96ff;
    color: #333;
    background: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
}

#notification:before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 40px;
    border-width: 20px 20px 0;
    border-style: solid;
    border-color: #4e96ff transparent;
    display: block;
    width: 0;
}

#notification:after {
    content: "";
    position: absolute;
    bottom: -11px;
    left: 47px;
    border-width: 13px 13px 0;
    border-style: solid;
    border-color: #fff transparent;
    display: block;
    width: 0;
}

.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }
}

#keyboard {
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    position: fixed;
    width: calc(((var(--key-size) + 10px) * 5)* 5/3);
    height: calc((var(--key-size) + 10px) * 5);
    border-radius: 14px;
    padding: 10px;
    box-shadow:  14px 14px 26px #3b71cf, 
                -14px -14px 26px #4d93ff;
}

.key-row {
    width: 100%;
    height: calc(var(--key-size) + 5px);
    margin: 5px;
    display: flex;
}

.key {
    margin: 0 5px;
    height: var(--key-size);
    width:  var(--key-size);
    display: flex;
    justify-content: center;
    align-items: center;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 8px;
    box-shadow:  5px 5px 10px #3b71cf, 
                -5px -5px 10px #4d93ff;
}

.key > div {
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: calc( var(--key-size) - 10px);
    height: calc( var(--key-size) - 10px);
    border-radius: 5px;
    background: linear-gradient(145deg, #3d75d6, #498bff);
    box-shadow:  4px 4px 8px #3b71cf, 
                 -4px -4px 8px #4d93ff;
}

.key > div.multi {
    display: flex;
    flex-direction: column;
}

.symbol {
    font-size: 12px;
    margin-bottom: 2px;
}

.pressed {
    box-shadow: inset 20px 20px 60px #3a6fca, 
                inset -20px -20px 60px #4e96ff;
}

.nib {
    width: calc(var(--key-size) * .20);
    height: 0.2vw;
    border-radius: 3px;
    position: absolute;
    bottom: 5px;
    box-shadow:  1px 1px 2px #3a6fca, 
                -1px -1px 2px #4e96ff;
}

.key.tab {
    width: calc(var(--key-size) * 1.5);
}

.key.tab > div {
    width: calc(var(--key-size)* 1.5 - 10px);
}

.key.caps {
    width: calc(var(--key-size)* 1.75);
}

.key.caps > div {
    width: calc(var(--key-size)*1.75 - 10px);
}

.key.shift {
    width: calc(var(--key-size)* 2.25);
}

.key.shift > div {
    width: calc(var(--key-size)* 2.25 - 10px);
}

.key.fn {
    width: calc(var(--key-size)* 1.25);
}

.key.fn > div {
    width: calc(var(--key-size)* 1.25 - 10px);
}

.key.space {
    width: calc(var(--key-size)* 6.25);
}

.key.space > div {
    width: calc(var(--key-size)* 6.25 - 10px);
    background: #4482EE;
}

.key.fn > div,
.key.shift > div,
.key.caps > div,
.key.tab > div,
.key.esc > div {
    font-size: 13px;
    padding-left: 12px;
    justify-content: flex-start;
}



/*
* Projects
*/
#better-crunchyroll {
    width: 270px;
    top: -20px;
}

#qr-code {
    width: 300px;
    top: -100px;
}

#watercooling {
    width: 325px;
    top: -125px;
}

#projects {
    z-index: 2;
    display: block;
    position: absolute;
    padding: 0 100px;
    margin: 100px 0 0 0;
}

#projects > div:last-of-type {
    margin-bottom: 50px;
}

.project-image-container {
    padding: 120px;
    width: 300px;
}

.project-svg {
    position: absolute;
    top: 0;
    right: -50px;
}

.project-card {
    position: relative;
    margin-top: 50px;
    overflow: hidden;
    padding: 55px;
    border-radius: 15px;
    height: 160px;
    box-shadow:  16px 16px 32px #3a6fca, 
                -16px -16px 32px #4e96ff;
}

.project-card > h1 {
    margin: 0;
    display: flex
}

h1 > span {
    display: flex;
    margin: 0 10px;
}

.project-link {
    height: 1.3em;
    width: 1.3em;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 10px;
    border-radius: 5px;
    box-shadow:  6px 6px 13px #3b71cf, 
                 -6px -6px 13px #4d93ff;
}

.project-link:hover {
    color: darkblue;
}

.project-content {
    position: relative;
    display: flex;
}

.project-description {
    overflow-y: auto;
    font-size: 1.2em;
}

/*
* About
*/
#about {
    z-index: 2;
    display: flex;;
    padding: 0 100px;
    margin: 150px 0 50px 0;
}

#profile-container {
    flex-direction: row;
    display: flex;
}

#profile {
    overflow: hidden;
    width: 350px;
    height: 350px;
    border-radius: 15px;
    box-shadow: 16px 16px 32px #3a6fca, 
                -16px -16px 32px #4e96ff
}

#profile > img {
    width: 410px;
}

#bio {
    margin: 0 0 0 100px;
}

#bio > h1 {
    margin: 0;
    font-size: 40px;
}

#bio > p {
    max-width: 90%;
    font-size: 1.2em;
}

#social-container {
    font-size: 2em;
    display: flex;
    margin: 50px 0;
    width: 350px;
    justify-content: space-evenly;
}

#social-container-mobile,
#mobile-about-header {
    display: none;
}

.social-button {
    width: 1.3em;
    height: 1.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow:  6px 6px 13px #3b71cf, 
                 -6px -6px 13px #4d93ff;
}

.social-button:hover {
    color: darkblue;
}

.social-button:active {
    color: white;
    box-shadow: inset 6px 6px 13px #3b71cf, 
                inset -6px -6px 13px #4d93ff;
}

.left {
    width: 40%;
}

.external {
    text-decoration: underline;

}

/* Medium Desktop */
@media screen and (max-width: 1700px) {
    /*
    * Project
    */
    .project-card {
        height: 250px;
    }

    #better-crunchyroll {
        width: 270px;
        top: -15px;
        right: -45px;
    }
    
    #qr-code {
        width: 300px;
        top: -45px;
    }
    
    #watercooling {
        width: 325px;
        top: -100px;
    }

    .project-image-container {
        padding: 100px;
    }

    .project-svg {
        position: absolute;
        top: 0px;
        right: -100px;
    }
}

/* Small Desktop */
@media screen and (max-width: 1050px) {
    .project-card {
        height: 300px;
    }
    #intro-container {
        width: 50%
    }
}

/* Iphone X */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    #intro-container {
        display: block;
        width: 100%
    }
    #notification {
        top: -125px;
        height: 50px;
        width: 300px;
    }

    .project-image-container {
        padding: 140px;
    }
}

/* Smartphones (portrait and landscape)*/
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    :root {
        --key-size: 90px;
    }

    body {
        font-size: 2.1em;
    }

    #intro-container {
        display: block;
        width: 100%;
    }

    #notification {
        top: -125px;
        height: 50px;
        width: 300px;
    }

    .nav {
        display: none;
        z-index: 3;
        background: #4482EE;
        height: 100%;
        width: 100%;
        position: fixed;
        flex-direction: column;
        left: 0;
        align-items: center;
        justify-content: space-evenly;
        top: 0;
        opacity: 0;
        font-size: 2em;
    }

    .nav.mobile-open {
        display: flex;
        pointer-events: all;
    }

    .indicator {
        height: 15px;
        width: 15px;
        bottom: -35px;
    }

    .mobile-nav {
        z-index: 4;
        font-size: 2.5em;
        position: absolute;
        top: 75px;
        right: 100px;
        display: block;
        width: 80px;
        height: 80px;
    }

    .bar1, .bar2, .bar3 {
        width: 70px;
        height: 6px;
        background-color: white;
        margin: 20px 0;
        transition: 0.4s;
        border-radius: 10px;
      }
      
      .change .bar1 {
        -webkit-transform: rotate(-45deg) translate(-23px, 4px);
        transform: rotate(-45deg) translate(-23px, 4px);
      }
      
      .change .bar2 {opacity: 0;}
      
      .change .bar3 {
        -webkit-transform: rotate(45deg) translate(-31px, -14px);
        transform: rotate(45deg) translate(-31px, -14px);
      }

    /*
    * Home
    */
    #home {
        align-items: unset;
        width: 100%;
    }

    #keyboard {
        right: -130px;
        top: 75%;
        transform: translateY(-75%);
        width: calc(((var(--key-size) + 10px) * 6)* 5/3)
    }

    .symbol,
    .key.fn > div,
    .key.shift > div,
    .key.caps > div,
    .key.tab > div,
    .key.esc > div {
        font-size: .7em;
    }

    .intro {
        font-size: 2.5em;
        margin: 100px 0;
    }

    .button {
        padding: 30px;
    }

    /*
    * Projects
    */

    #better-crunchyroll {
        width: 370px;
        top: -20px;
        right: -75px;
    }

    #watercooling {
        right: 100px;
        top: -90px;
        transform: rotate(113deg) scale(1.3) translateY(-150px) translateX(-55px);
    }

    #qr-code {
        width: 350px;
        top: -70px;
    }

    #projects {
        margin: 150px 0 50px;
    }

    .project-card {
        height: 900px;
        margin-top: 100px;
        padding: 50px 55px 100px;
    }

    .project-image-container {
        position: absolute;
        bottom: -100px;
        right: 0;
    }

    .project-description > .external {
        display: block;
        max-width: 350px;
    }

    /*
    * About
    */
    #about {
        flex-direction: column;
        margin: 190px 0 50px;
    }

    #profile {
        margin: 0 auto;
        width: 450px;
        height: 450px;
    }

    #profile > img {
        width: 510px;
    }

    #social-container {
        margin: 50px auto;
    }

    #resume,
    #social-container,
    #bio > h1 {
        display: none;
    }

    #mobile-about-header {
        display: block;
        text-align: center;
    }

    #bio {
        margin: 0;
    }

    #bio > p {
        margin: 80px 0 0 0;
        max-width: 100%;
        font-size: inherit;
    }

    #social-container-mobile {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 70px;
    }

    #social-container-mobile > .social-button {
        width: 1.8em;
        height: 1.8em;
        font-size: 2em;
    }

    #social-container-mobile > .button {
        margin: 0;
    }

    .left {
        width: 100%;
    }
}


/* Small mobile */
@media only screen and (max-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait){
    #better-crunchyroll {
        top: -50px;
    }

    #watercooling {
        top: -100px;
    }

    #qr-code{
        top: -70px;
    }

    .project-description > .external {
        max-width: 350px;
    }
}

/* IPhone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    #watercooling {
        top: -50px;
    }
    
    .project-card {
        height: 1200px;
    }
}
